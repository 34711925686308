import {
  CampaignRow,
  CampaignStatus,
  MotiveOverviewRow,
} from '@/features/campaigns/domain';
import { TemplateNames } from '@/components/table';
import { t } from '@/services/i18n';
import { sortByDate } from '@/components/table/utils/sortRows';

export const sortByStatus = (row1: CampaignRow, row2: CampaignRow) => {
  if (row1.status === row2.status) return 0;
  if (row1.status === CampaignStatus.ACTIVE) return -1;
  return 1;
};

export const overviewListTableHeaders = [
  {
    id: 'name',
    label: t('overview.tableHeaders.motiveName'),
    templateName: TemplateNames.TEXT,
    laptopOnly: true,
    sort: (row1: MotiveOverviewRow, row2: MotiveOverviewRow) =>
      row1.name.localeCompare(row2.name),
    extraClasses: 'w-laptopMotiveColWidth rounded-2 truncate text-ellipsis',
    searchable: true,
  },
  {
    id: 'uniqueClickUrls',
    label: t('overview.tableHeaders.clickUrl'),
    templateName: TemplateNames.CLICK_URLS,
    laptopOnly: true,
    extraClasses: 'w-motiveDateColWidth',
    searchable: true,
  },
  {
    id: 'description',
    label: t('overview.tableHeaders.description'),
    templateName: TemplateNames.TEXT,
    sort: (row1: MotiveOverviewRow, row2: MotiveOverviewRow) =>
      row1.description?.localeCompare(row2.description),
    laptopOnly: true,
    extraClasses: 'w-laptopCampaignColWidth truncate text-ellipsis',
    searchable: true,
  },
  {
    id: 'campaignName',
    label: t('overview.tableHeaders.campaign'),
    templateName: TemplateNames.CAMPAIGN_LINK,
    sort: (row1: MotiveOverviewRow, row2: MotiveOverviewRow) =>
      row1.campaignName.localeCompare(row2.campaignName),
    laptopOnly: true,
    extraClasses: 'w-laptopCampaignColWidth',
    searchable: true,
  },
  {
    id: 'startDate',
    label: t('overview.tableHeaders.startDate'),
    sort: (row1: CampaignRow, row2: CampaignRow) =>
      sortByDate(row1, row2, 'startDate'),
    templateName: TemplateNames.DATE,
    laptopOnly: true,
    extraClasses: 'w-dateColWidth',
  },
  {
    id: 'endDate',
    label: t('overview.tableHeaders.endDate'),
    sort: (row1: CampaignRow, row2: CampaignRow) =>
      sortByDate(row1, row2, 'endDate'),
    templateName: TemplateNames.DATE,
    laptopOnly: true,
    extraClasses: 'w-dateColWidth',
  },
  {
    id: 'status',
    label: t('overview.tableHeaders.status'),
    sort: sortByStatus,
    templateName: TemplateNames.STATUS,
    extraClasses: 'w-mobileStatusColWidth md:w-laptopStatusColWidth',
  },
  {
    id: '',
    label: '',
    templateName: TemplateNames.DROPDOWN,
    extraClasses: 'md:w-threeDotsColWidth rounded-2',
  },
];
