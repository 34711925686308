<template>
  <app-layout>
    <div class="flex flex-col md:mx-4">
      <!-- Laptop only -->
      <div class="laptop-only">
        <div class="flex flex-row justify-between items-center">
          <app-txt variant="h1" class="text-white">{{
            $t('campaigns.title')
          }}</app-txt>
          <div class="flex-row flex items-center">
            <app-input
              :placeholder="$t('campaigns.search')"
              leftIcon="search"
              v-model="searchedValue"
            />
            <div class="h-newCampaignHeight">
              <app-button
                leftIcon="plusCircle"
                @click="onCreateNewCampaign"
                class="ml-3"
                >{{ $t('campaigns.newCampaign') }}</app-button
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile only -->

      <div class="mobile-only">
        <div class="mx-2">
          <app-txt variant="h3" class="text-white">{{
            $t('campaigns.title')
          }}</app-txt>
          <div class="flex-row flex items-center mt-2">
            <app-input
              :placeholder="$t('campaigns.search')"
              leftIcon="search"
              v-model="searchedValue"
            />

            <app-button
              leftIcon="plusCircle"
              @click="onCreateNewCampaign"
              class="ml-2"
              >{{ $t('campaigns.new') }}</app-button
            >
          </div>
        </div>
      </div>

      <app-tabs
        class="mt-3 md:mt-3"
        :tabs="tabs"
        :selectedTabId="selectedTabId"
        @change-tab="changeTab"
      />

      <div v-if="isBinTab" class="mobile-only bg-white w-full pl-2">
        <app-button
          variant="base"
          leftIcon="deleteRed"
          class="text-tiny text-red-600 font-medium h-bulkEditBtn border border-red-600 px-2 focus:outline-none my-1.5"
          @click="onEmptyBin"
        >
          {{ $t('campaigns.emptyBin') }}
        </app-button>
      </div>

      <app-table
        :headers="headers"
        :rows="campaigns"
        :searchedValue="searchedValue"
        :onRowClick="onRowClick"
        class="mb-4"
      >
        <template v-slot:[TemplateNames.TEXT]="{ value }"
          ><app-txt>{{ value }}</app-txt></template
        >
        <template v-slot:[TemplateNames.STATUS]="{ value }"
          ><StatusCell :value="value"
        /></template>

        <template v-slot:[TemplateNames.DATE]="{ value }"
          ><DateCellWithEndingDays :value="value"
        /></template>

        <template v-slot:[TemplateNames.DROPDOWN]="{ rowId }"
          ><CampaignListDropdownCell
            :campaignId="rowId"
            @edit-campaign="onEditCampaign"
            @add-motive="onAddMotive"
        /></template>

        <template
          v-slot:[TemplateNames.TEXT_WITH_DESCRIPTION]="{ value, description }"
        >
          <TextWithDescriptionCell :value="value" :description="description" />
        </template>

        <template v-slot:[TemplateNames.EMPTY_STATE]>
          <CampaignListEmptyState
            @create-campaign="onCreateNewCampaign"
            :isNoSearchResult="!!searchedValue && !!campaigns.length"
          />
        </template>

        <template
          v-slot:[TemplateNames.SORTABLE_HEADER]="{
            header,
            sortBy,
            sortOrder,
            changeSortedColumn,
          }"
          ><SortableHeaderCell
            :sortBy="sortBy"
            :header="header"
            :sortOrder="sortOrder"
            :changeSortedColumn="changeSortedColumn"
        /></template>

        <template v-slot:[TemplateNames.EMPTY_BIN_HEADER]>
          <EmptyBinHeaderCell :isVisible="isBinTab" @empty-bin="onEmptyBin" />
        </template>
      </app-table>
    </div>
    <CreateCampaignModal
      :isModalOpen="isModalOpen"
      @close-modal="() => setIsModalOpen(false)"
    />
    <EditCampaignModal
      v-if="currentlyEditedCampaign"
      :campaign="currentlyEditedCampaign"
      :isModalOpen="!!currentlyEditedCampaign"
      @close-modal="resetCurrentlyEditedCampaign"
    />
    <CreateMotiveModal
      v-if="!!campaignIdToAddMotive"
      :isModalOpen="!!campaignIdToAddMotive"
      @close-modal="resetCampaignIdToAddMotive"
      :campaignId="campaignIdToAddMotive"
    />
    <confirm-deletion-modal
      :title="$t('common.deletionModal.title')"
      :confirm-label="$t('common.deletionModal.confirmDeleteLabel')"
      :cancel-label="$t('common.deletionModal.cancel')"
      :description="$t('common.deletionModal.description')"
      :delete-function="emptyCampaignBin"
      :is-modal-open="isConfirmEmptyCampaignBinModalOpen"
      @close-modal="() => setIsConfirmEmptyCampaignBinModalOpen(false)"
    />
  </app-layout>
</template>

<script lang="ts">
import { TemplateNames } from '@/components/table';
import {
  DateCellWithEndingDays,
  StatusCell,
  TextWithDescriptionCell,
  SortableHeaderCell,
} from '../../../../components/table/cellTemplates';
import CampaignListDropdownCell from './campaignListDropdownCell.vue';
import { useFetchCampaigns } from '../../application/campaign/actions/useFetchCampaigns';
import { useGetCampaignTableProps } from '../../application/campaign/list/useGetCampaignTableProps';
import { campaignListTableHeaders } from '../../application/campaign/list/campaignListTableHeaders';
import { computed, ref } from 'vue';
import { Tabs } from '../../domain';
import CampaignListEmptyState from './campaignListEmptyState.vue';
import CreateCampaignModal from './createCampaignModal.vue';
import { useGetActiveTab } from '../../application/campaign/list/useGetActiveTab';
import { useRouter } from 'vue-router';
import { campaignsRouteNames } from '../routes/campaignsRouteNames';
import EmptyBinHeaderCell from './emptyBinHeaderCell.vue';
import { Campaign } from '../../domain/campaign';
import EditCampaignModal from '../campaignDetails/editCampaignModal.vue';
import CreateMotiveModal from '../campaignDetails/createMotiveModal/createMotiveModal.vue';
import { useEmptyCampaignBin } from '@/features/campaigns/application/campaign/useEmptyCampaignBin';
import { useFetchMotives } from '../../application/motive/actions/useFetchMotives';

export default {
  components: {
    StatusCell,
    DateCellWithEndingDays,
    CampaignListDropdownCell,
    TextWithDescriptionCell,
    CampaignListEmptyState,
    CreateCampaignModal,
    SortableHeaderCell,
    EmptyBinHeaderCell,
    EditCampaignModal,
    CreateMotiveModal,
  },
  setup() {
    useFetchCampaigns({});
    useFetchMotives({});
    const searchedValue = ref('');

    const isModalOpen = ref(false);
    const setIsModalOpen = (value: boolean) => {
      isModalOpen.value = value;
    };

    const selectedTabId = useGetActiveTab();

    const { campaigns, tabs } = useGetCampaignTableProps(selectedTabId);

    const changeTab = (newTab: Tabs) => {
      selectedTabId.value = newTab;
    };

    const onCreateNewCampaign = () => {
      setIsModalOpen(true);
    };

    const router = useRouter();
    const onRowClick = (id: string) => {
      router.push({ name: campaignsRouteNames.campaignDetails, query: { id } });
    };

    const isBinTab = computed(() => selectedTabId.value === Tabs.bin);

    const isConfirmEmptyCampaignBinModalOpen = ref(false);
    const setIsConfirmEmptyCampaignBinModalOpen = (value: boolean) => {
      isConfirmEmptyCampaignBinModalOpen.value = value;
    };
    const onEmptyBin = () => {
      setIsConfirmEmptyCampaignBinModalOpen(true);
    };

    const currentlyEditedCampaign = ref<Campaign>();
    const resetCurrentlyEditedCampaign = () => {
      currentlyEditedCampaign.value = undefined;
    };
    const onEditCampaign = (campaign: Campaign) => {
      currentlyEditedCampaign.value = campaign;
    };

    const campaignIdToAddMotive = ref('');
    const resetCampaignIdToAddMotive = () => {
      campaignIdToAddMotive.value = '';
    };
    const onAddMotive = (campaignId: string) => {
      campaignIdToAddMotive.value = campaignId;
    };

    const { call } = useEmptyCampaignBin();
    const emptyCampaignBin = async () => {
      await call({});
      setIsConfirmEmptyCampaignBinModalOpen(false);
    };

    return {
      emptyCampaignBin,
      TemplateNames,
      headers: campaignListTableHeaders,
      campaigns,
      onCreateNewCampaign,
      tabs,
      selectedTabId,
      changeTab,
      isModalOpen,
      setIsModalOpen,
      searchedValue,
      onRowClick,
      isBinTab,
      onEmptyBin,
      isConfirmEmptyCampaignBinModalOpen,
      setIsConfirmEmptyCampaignBinModalOpen,
      onEditCampaign,
      currentlyEditedCampaign,
      resetCurrentlyEditedCampaign,
      onAddMotive,
      campaignIdToAddMotive,
      resetCampaignIdToAddMotive,
    };
  },
};
</script>
